var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "mew-component--module-side-apr bgWalletBlockDark pa-5 border-radius--10px",
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between mb-1" },
        [
          _c(
            "div",
            {
              staticClass:
                "textLight--text text-uppercase mew-label font-weight-medium",
            },
            [_vm._v(" Current APR ")]
          ),
          _c("div", { staticClass: "textLight--text greenPrimary--text" }, [
            _vm._v(_vm._s(_vm.validatorApr) + "%"),
          ]),
        ]
      ),
      _c("div", { staticClass: "d-flex align-center justify-space-between" }, [
        _c(
          "div",
          {
            staticClass:
              "textLight--text text-uppercase mew-label font-weight-medium",
          },
          [_vm._v(" ETH in pool ")]
        ),
        _c("div", [
          _vm._v(
            _vm._s(_vm.formattedPoolValue) + " " + _vm._s(_vm.networkName)
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }